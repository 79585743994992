<template>
  <div class="m-main">
    <div class="active">
      <img src="~@/assets/mobileActivity/top.png" alt="">
      <img class="enter" src="~@/assets/mobileActivity/enter.png" alt="" @click="$router.push('/')">
      <img class="pointer" src="~@/assets/mobileActivity/pointer.png" alt="">
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
.active {
  width: 100%;
  height: 498px;
  background: url("~@/assets/mobileActivity/bg.jpg") no-repeat;
  background-size: 100% 100%;
  background-position: 0 50px;
  img {
    width: 100%;
  }
  .enter {
    display: block;
    width: 40%;
    margin-left: 56%;
    margin-top: -49px;
  }
  .pointer {
    display: block;
    width: 10%;
    margin: 0 auto;
    margin-top: 320px;
  }
}
</style>
