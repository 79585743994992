<template>
  <div>
    <Head />
    <Banner />
    <MobileFloor />
  </div>
</template>
<script>
import MobileFloor from "@/components/MobileFloor";
import Head from "./models/Head";
import Banner from "./models/Banner";
export default {
  name: "Activity",
  components: {
    Head,
    Banner,
    MobileFloor
  },
  data() {
    return {};
  }
};
</script>
