<template>
  <div class="banner-img">
    <ul class="nav">
      <li
        v-for="(item, index) in navList"
        :key="index"
        @click="navClick(item, index)"
        :class="{ active: item.id === activeId }"
      >
        <span> {{ item.title }}</span>
      </li>
    </ul>
    <div class="main-img">
      <div v-for="temp in introduceList" :key="temp.id">
        <img :src="temp.path" alt="" />
        <span v-show="expectShow">敬请期待...</span>
        <!-- <span v-if="item.id === 3">敬请期待</span> -->
      </div>
    </div>
    <!-- <img src="~@/assets/mobileActivity/active.jpg" alt="" />
    <img src="~@/assets/mobileActivity/set.jpg" alt="" />
    <img src="~@/assets/mobileActivity/people.jpg" alt="" /> -->
  </div>
</template>
<script>
import rule1 from "@/assets/mobileActivity/rule1.jpg";
import rule2 from "@/assets/mobileActivity/rule2.jpg";
import rule3 from "@/assets/mobileActivity/rule3.jpg";
import active from "@/assets/mobileActivity/active.jpg";
import set from "@/assets/mobileActivity/set.jpg";
import people from "@/assets/mobileActivity/people.jpg";
import rule4 from "@/assets/mobileActivity/rule4.jpg";
const introductDefault = [
  { id: 1, path: active },
  { id: 2, path: set },
  { id: 3, path: people }
];
export default {
  data() {
    return {
      navList: [
        { id: 1, title: "活动介绍" },
        { id: 2, title: "活动规则" },
        { id: 3, title: "作品展示" },
        { id: 4, title: "获奖作品" }
      ],
      expectShow: false,
      gameList: [
        { id: 1, path: rule1 },
        { id: 2, path: rule2 },
        { id: 3, path: rule3 }
      ],
      introduceList: Object.assign({}, introductDefault),
      activeId: 1,
      noneList: [{ id: 1, path: rule4 }]
    };
  },
  methods: {
    navClick(item, index) {
      this.activeId = item.id;
      if (item.id === 1) {
        this.expectShow = false;
        this.introduceList = Object.assign({}, introductDefault);
      } else if (item.id === 2) {
        this.expectShow = false;
        this.introduceList = this.gameList;
      } else if (item.id === 3 || item.id === 4) {
        this.expectShow = true;
        this.introduceList = this.noneList;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.banner-img {
  padding-top: 10px;
  .nav {
    width: 355px;
    height: 32px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: url("~@/assets/activity/nav.png") no-repeat;
    background-size: 100% 100%;
    li {
      display: inline-block;
      margin-left: 32px;
      span {
        color: #ffffff;
        font-size: 7px;
        line-height: 16px;
        text-shadow: 0px 1px 0px rgba(196, 194, 194, 0.4);
        background: linear-gradient(0deg, #ffffff 0%, #f7f0e4 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .active {
      span {
        color: #ffd100;
        font-size: 7px;
        line-height: 16px;
        text-shadow: 0px 1px 0px rgba(224, 203, 8, 0.4);
        background: linear-gradient(0deg, #ffd100 0%, #db951c 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .main-img {
    font-size: 0;
    margin-top: -10px;
    img {
      width: 100%;
      height: 100%;
    }
    span {
      color: rgb(129, 128, 122);
      position: absolute;
      top: 100%;
      left: 50%;
      display: block;
      font-size: 24px;
      transform: translateX(-50%);
      z-index: 9;
    }
  }
}
</style>
